import ReportStudentRepository from '@/shared/http/repositories/socialProject/report-student'
import BreadCrumb from '@/components/layout/BreadCrumb/breadCrumb.vue'
import Pagination from '@/components/layout/Pagination/pagination.vue'
import Search from '@/components/layout/Search/search.vue'
import { loading, toast } from '@/shared/utils/UIHelper'
import { IsSuccessCode } from '@/shared/utils/API'
import { dateFormattedFilter } from '@/shared/filters/ToDateFormatted'
import StudentReportCriteria from '@/shared/models/criteria/studentreportcriteria.js'

export default {
  components: {
    [BreadCrumb.name]: BreadCrumb,
    [Pagination.name]: Pagination,
    [Search.name]: Search
  },

  data: () => ({
    emptyText: 'Realize uma pesquisa para o preenchimento desta tabela.',
    emptytextStudents: 'Preencha esta tabela com informações da tabela acima.',
    fixed: true,
    students: [],
    studentsSelected: [],
    criteria: new StudentReportCriteria(),
    pagination: {
      count: 1,
      limit: 20,
      index: 1
    },
    fields: [
      {
        key: 'action',
        label: 'ADICIONAR EM RELATÓRIO',
        class: 'left-header-border text-center'
      },
      {
        key: 'code',
        label: 'CÓDIGO',
        class: 'middle-header text-center'
      },
      {
        key: 'name',
        label: 'NOME',
        class: 'middle-header text-center',
        sortable: true
      },
      {
        key: 'gender',
        label: 'SEXO',
        class: 'middle-header text-center'
      },
      {
        key: 'age',
        label: 'IDADE',
        class: 'middle-header text-center'
      },
      {
        key: 'socialEntityName',
        label: 'ENTIDADE',
        class: 'middle-header text-center'
      },
      {
        key: 'modalityName',
        label: 'MODALIDADE',
        class: 'middle-header text-center'
      },
      {
        key: 'localTrainingName',
        label: 'LOCAL DE TREINAMENTO',
        class: 'middle-header text-center'
      },
      {
        key: 'healthPlanName',
        label: 'PLANO DE SAÚDE',
        class: 'middle-header text-center'
      },
      {
        key: 'schoolName',
        label: 'ESCOLA',
        class: 'middle-header text-center'
      },
      {
        key: 'familyIncome',
        label: 'RENDA FAMILIAR',
        class: 'middle-header text-center'
      },
      {
        key: 'familyBag',
        label: 'BOLSA FAMÍLIA',
        class: 'middle-header text-center'
      },
      {
        key: 'motherName',
        label: 'MÃE',
        class: 'middle-header text-center'
      },
      {
        key: 'fatherName',
        label: 'PAI',
        class: 'middle-header text-center'
      },
      {
        key: 'birthDate',
        label: 'DATA DE NASCIMENTO',
        class: 'middle-header text-center',
        formatter: dateFormattedFilter
      },
      {
        key: 'status',
        label: 'STATUS',
        class: 'middle-header text-center'
      },
      {
        key: 'yearRegistration',
        label: 'ANO DE MATRÍCULA',
        class: 'middle-header text-center'
      },
      {
        key: 'cpf',
        label: 'CPF',
        class: 'middle-header text-center'
      },
      {
        key: 'rg',
        label: 'RG',
        class: 'middle-header text-center'
      },
      {
        key: 'dateLastUpdate',
        label: 'DATA DA ÚLTIMA ALTERAÇÃO',
        class: 'right-header-border text-center',
        formatter: dateFormattedFilter
      }
    ],
    fieldsStudentsSelected: [
      {
        key: 'action',
        label: 'REMOVER DE RELATÓRIO',
        class: 'left-header-border text-center'
      },
      {
        key: 'code',
        label: 'CÓDIGO',
        class: 'middle-header text-center'
      },
      {
        key: 'name',
        label: 'NOME',
        class: 'middle-header text-center',
        sortable: true
      },
      {
        key: 'cpf',
        label: 'CPF',
        class: 'middle-header text-center'
      },
      {
        key: 'rg',
        label: 'RG',
        class: 'middle-header text-center'
      },
      {
        key: 'birthDate',
        label: 'DATA DE NASCIMENTO',
        class: 'right-header-border text-center',
        formatter: dateFormattedFilter
      }
    ]
  }),

  computed: {
    breadCrumb() {
      return {
        breadcrumbItems: [
          {
            text: 'Dashboard',
            href: this.$router.resolve({ name: 'Dashboard' }).href
          },
          {
            text: 'Relatórios',
            href: this.$router.resolve({ name: 'ReportStudentList' }).href
          }
        ]
      }
    }
  },

  methods: {
    clearForm() {
      this.criteria = new StudentReportCriteria()
    },

    search() {
      loading.push()
      ReportStudentRepository.Search(this.criteria, this.pagination)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.students = res.data.data
          this.pagination = {
            count: res.data.count,
            limit: res.data.pagingLimit,
            index: res.data.pagingIndex
          }
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar alunos', 'ERRO')
        })
    },

    addSelectedStudents(item) {
      const student = this.students.find(s => s.id === item.id)
      if (!student) return

      const studentIndex = this.students.findIndex(s => s.id = student.id)
      this.students.splice(studentIndex, 1)

      this.studentsSelected.push(student)
    },

    removeSelectedStudents(item) {
      const student = this.studentsSelected.find(s => s.id === item.id)

      const studentIndex = this.studentsSelected.findIndex(s => s.id === student.id)
      this.studentsSelected.splice(studentIndex, 1)

      this.students.push(student)
    }
  }
}
